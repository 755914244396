import university1 from "../assets/img/university/university1.jpg"
import university2 from "../assets/img/university/university2.jpg"
import university3 from "../assets/img/university/university3.png"
import university4 from "../assets/img/university/university4.jpeg"
import university5 from "../assets/img/university/university5.jpg"
export const UniversityImages=[
    {
        src:university1,
        id:1,
        title:"Université de Liège"
    },
    {
        src:university2,
        id:2,
        title:"Université de Mons"
    },
    {
        src:university3,
        id:3,
        title:"Université de Namur"
    },
    {
        src:university4,
        id:4,
        title:"Université de Bruxelles"
    },
    {
        src:university5,
        id:5,
        title:"Université de Louvain"
    }
]